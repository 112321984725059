/*global $, Stripe, stripe_publishable_key, addLoader */

                const purchaseForm = () => {
                    $(document).ready(function () {
                        const compliance_pack_type = $(
                            '#purchase-form--about input[name="compliance_pack_type"]'
                        ).val();
                        if (compliance_pack_type) {
                            $(".block--policy").hide();
                            $(".block--policy--" + compliance_pack_type)
                                .removeClass("hidden hide")
                                .show();
                        }
                    });
                    $(function () {
                        $('#purchase-form--about input[name="compliance_pack_type"]').on(
                            "change",
                            function () {
                                var compliance_pack_type = $(this).val();
                                var $form = $("#purchase-form--about");

                                var product_name_field = $form
                                    .find('input[name="product_name"]')
                                    .closest(".field");

                                var website_address_field = $form
                                    .find('input[name="website_address"]')
                                    .closest(".field");

                                if (compliance_pack_type === "comprehensive-app") {
                                    product_name_field.removeClass("hidden hide").show();
                                    website_address_field.hide().find('input').prop('disabled', true);
                                } else {
                                    product_name_field.hide();
                                    website_address_field.removeClass("hidden hide").show().find('input').prop('disabled', false);
                                }

                                var language_dropdown_field = $form.find("#language-dropdown");

                                if (compliance_pack_type === "website-free") {
                                    language_dropdown_field.hide();
                                } else {
                                    language_dropdown_field.removeClass("hidden hide").show();
                                }

                                $(".block--policy").hide();
                                $(".block--policy--" + compliance_pack_type)
                                    .removeClass("hidden hide")
                                    .show();
                            }
                        );

                        $('#purchase-form--about select[name="compliance_pack_type"]').trigger("change");

                        // Just style the submit button during a natural submit.
                        $(
                            "#purchase-form--about, #purchase-form--options, #purchase-form--payment-free"
                        ).on("submit", function () {
                            const $form = $(this);
                            const button = $form.get(0).querySelector('button[type="submit"]');
                            const loader = addLoader();
                            button.classList.add("btn--is-loading");
                            button.setAttribute("disabled", "disabled");
                            button.prepend(loader);
                        });

                        // $("#purchase-form--payment-free").on("submit", function(e) {
                        //     e.preventDefault();
                        //     alert("TODO");
                        // });

                        $("#purchase-form--payment").on("submit", function (e) {
                            e.preventDefault();
                            var $form = $(this);
                            var button = $form.get(0).querySelector('button[type="submit"]');

                            // Hide all errors.
                            $form
                                .find("[data-error-for]")
                                .addClass("hidden")
                                .hide();

                            $form.find("[data-error-input]").removeClass("error");
                            $form.find(".review-errors").hide();

                            const loader = addLoader();
                            button.classList.add("btn--is-loading");
                            button.setAttribute("disabled", "disabled");
                            button.prepend(loader);

                            $.ajax({
                                type: "POST",
                                url: $form.attr("action"),
                                //data: $form.find("input, select").serialize(),
                                data: [
                                    $form.find("input, select").serialize(),
                                    $.param({
                                        subscription_type: $form
                                            .find('input[name="subscription_type"]:checked')
                                            .val(),
                                    }),
                                    $.param({
                                        agency_policy: $form
                                            .find('input[name="agency_policy"]')
                                            .val(),
                                    }),
                                    $.param({
                                        appsumo_policy: $form
                                            .find('input[name="appsumo_policy"]')
                                            .val(),
                                    }),
                                ].join("&"),
                                dataType: "json",
                            })
                                .done(function (data) {
                                    if (data.stripe_session) {
                                        window.stripe = Stripe(stripe_publishable_key);
                                        window.stripe.redirectToCheckout({
                                            sessionId: data.stripe_session,
                                        });
                                    }
                                    if (data.agency === "success") {
                                        if (data.incomplete) {
                                            window.location = "/home";
                                        } else {
                                            window.location = "/policy/" + data.token;
                                        }
                                    }
                                    if (data.appsumo === "success") {
                                        if (data.incomplete) {
                                            window.location = "/home";
                                        } else {
                                            window.location = "/policy/" + data.token;
                                        }
                                    }

                                    button.classList.remove("btn--is-loading");
                                    button.removeAttribute("disabled");
                                    loader.remove();
                                })
                                .fail(function (xhr) {
                                    var data = xhr.responseJSON;
                                    var errors = data.errors;
                                    Object.keys(errors).forEach(field_name => {
                                        if (
                                            field_name == "terms_agree" ||
                                            field_name == "submission"
                                        ) {
                                            // Just show.
                                            $('[data-error-for="' + field_name + '"]')
                                                .removeClass("hidden hide")
                                                .show();

                                            $('input[name="' + field_name + '"]').addClass(
                                                "error"
                                            );
                                        }
                                    });

                                    $form
                                        .find(".review-errors")
                                        .removeClass("hidden hide")
                                        .show();
                                    button.removeAttribute("disabled");
                                    button.classList.remove("btn--is-loading");
                                    loader.remove();
                                });
                        });
                    });
                };

                export default purchaseForm;
